import { template as template_aefdac7062d548089c148c4698dee2db } from "@ember/template-compiler";
import Component from "@glimmer/component";
import icon from "discourse-common/helpers/d-icon";
export default class FKErrors extends Component {
    concatErrors(errors) {
        return errors.join(", ");
    }
    static{
        template_aefdac7062d548089c148c4698dee2db(`
    <p class="form-kit__errors" id={{@id}} aria-live="assertive" ...attributes>
      <span>
        {{icon "triangle-exclamation"}}
        {{this.concatErrors @error.messages}}
      </span>
    </p>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
