import { template as template_01061b51b9cf4da499b330d95ad0e595 } from "@ember/template-compiler";
const FKLabel = template_01061b51b9cf4da499b330d95ad0e595(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
