import { template as template_7420733afe6b4fd591bd35e9011f9084 } from "@ember/template-compiler";
const FKText = template_7420733afe6b4fd591bd35e9011f9084(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
