import { template as template_78eb5be584a84b9e908dc6a51b46f900 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_78eb5be584a84b9e908dc6a51b46f900(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
