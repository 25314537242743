import { template as template_9b8a87038796441a8514e24f7a55b4e5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_9b8a87038796441a8514e24f7a55b4e5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
